<template>
  <column1>
    <Summary></Summary>
  </column1>
</template>

<script>
import AvailabilityCalendar from '@/components/lib/calendar/AvailabilityCalendar';
import Nav from '@/components/lib/Nav/MainNav';
import InfoBox from '@/components/lib/InfoBox/InfoBox';
import Summary from '@/components/lib/Summary/Summary';
import ButtonFloating from '@/components/lib/ButtonFloating/ButtonFloating';
import Column1 from '@/components/lib/Layout/Column1';

export default {
  name: 'SummaryView',
  components: {
    Column1,
    'info-box': InfoBox,
    Summary: Summary,
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>

/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <div v-if="mounted" class="summary">
      <loading v-if="loading || isNil(roomTypeAvailability)" />
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('summary.headline') }}
        </h1>
        <div class="booking-details border-grayLight border-1 rounded-8 py-24 mb-32 bg-white">
          <div class="arrival-departure px-24 border-b border-grayLight pb-24 mb-24">
            <div class="title flex justify-between mb-8">
              <span class="font-semibold text-14 text-dark">{{ $t('summary.dates') }}</span>
              <button>
                <span class="text-14 text-primary font-medium text-right block" @click="$router.push({ name: 'home' })">{{ $t('summary.change') }}</span>
              </button>
            </div>
            <div class="details">
              <div class="mb-4">
                <span class="text-16 text-dark">{{ formattedDateRange }}</span>
              </div>
              <div>
                <span class="text-14 text-gray">{{ $tc('summary.nights', numberNights, [numberNights]) }}</span>
              </div>
            </div>
            <div class="edit">
            </div>
          </div>

          <div class="guests px-24 border-b border-grayLight pb-24 mb-24">
            <div class="title flex justify-between mb-8">
              <span class="font-semibold text-14 text-dark">{{ $t('summary.guests') }}</span>
              <button>
                <span class="text-14 text-primary font-medium text-right block" @click="$router.push({ name: 'roomcount' })">{{ $t('summary.change') }}</span>
              </button>
            </div>
            <div class="details">
              <div class="mb-4">
                <span class="text-16 text-dark">{{ $tc('summary.roomCount', roomRequests.length, [roomRequests.length]) }}</span>
              </div>
              <div class="text-14">
                <template v-for="roomRequest in roomRequests">
                  <i18n path="roomSelect.roomRequestDescription" tag="span" class="text-gray block">
                    <template v-slot:adults>
                      {{ $tc('roomSelect.roomRequestDescription1', roomRequest.numAdults, [roomRequest.numAdults]) }}
                    </template>
                    <template v-if="!isEmpty(roomRequest.childAgeCategories)" v-slot:children>
                      {{ $tc('roomSelect.roomRequestDescription2', roomRequest.childAgeCategories.length, [roomRequest.childAgeCategories.length]) }}
                    </template>
                  </i18n>
                </template>
              </div>
            </div>
            <div class="edit">
            </div>
          </div>

          <template v-for="(roomRequest, idx) in roomRequests">
            <template v-for="roomType in [roomTypeMap[selectedOffersMap[roomRequest.id].roomTypeId]]">
              <template v-for="ratePlan in [ratePlanMap[selectedOffersMap[roomRequest.id].ratePlanId]]">
                <div class="room px-24 border-b border-grayLight pb-24 mb-24">
                  <div class="title flex justify-between mb-8">
                    <span v-if="roomRequests.length === 1" class="font-semibold text-14 text-dark">
                      {{ $tc('mainNav.room', roomRequests.length) }}
                    </span>
                    <span v-else class="font-semibold text-14 text-dark">
                      {{ $t('mainNav.nthRoom', [idx + 1]) }}
                    </span>
                    <button>
                      <span class="text-14 text-primary font-medium text-right block" @click="$router.push({ name: 'roomselect', params: { roomRequestId: roomRequest.id } })">{{ $t('summary.change') }}</span>
                    </button>
                  </div>
                  <div class="details flex relative">
                    <div class="image rounded-3 w-64 h-64 mr-16 overflow-hidden">
                      <template v-if="isEmpty(roomType.thumbImages)">
                        <img class="object-cover w-full h-full" :src="brokenImageUrl" alt="xxx">
                      </template>
                      <template v-else>
                        <img class="object-cover w-full h-full" :src="roomType.thumbImages[0]" alt="xxx">
                      </template>
                    </div>
                    <div>
                      <div class="mb-4">
                        <span class="text-16 text-dark">{{ roomType.name[locale] }}</span>

                        <info-icon v-tooltip="{ content: getRatePlanTooltip(roomRequest.id, roomType, ratePlan) }"
                                   size="24"
                                   class="text-grayBlue cursor-help inline-block ml-6 absolute right-0"
                        ></info-icon>
                      </div>
                      <div>
                        <span class="text-gray text-14">{{ ratePlan.name[locale] }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="displayShoppingCart(roomRequest.id)" class="addons mt-16 text-16 text-dark">
                    <div class="flex justify-between mb-8">
                      <span class="font-semibold text-14 text-dark">{{ $t('summary.selectedAdditionalServices') }}</span>
                      <button>
                        <span class="text-14 text-primary font-medium text-right block"
                              @click="$router.push({
                                name: 'roomaddons',
                                params: {
                                  roomRequestId: roomRequest.id,
                                  roomTypeId: roomType.id,
                                  ratePlanId: ratePlan.id,
                                },
                              })"
                        >{{ $t('summary.change') }}</span>
                      </button>
                    </div>
                    <ul v-if="!isEmpty(selectedShoppingCartItems.find(it => it.roomRequestId === roomRequest.id))" class="addons__list">
                      <template v-for="shoppingCartItem in selectedShoppingCartItems.find(it => it.roomRequestId === roomRequest.id).shoppingCartItems">
                        <li v-if="shoppingCartItem.selected === true && shoppingCartItem.count > 0">
                          <span>{{ shoppingCartItem.count }} x {{ inHouseServiceMap[shoppingCartItem.inHouseServiceId].name[locale] }}</span>
                          <div class="info">
                            <button>
                              <info-icon v-if="!isEmpty(inHouseServiceMap[shoppingCartItem.inHouseServiceId].description[locale])"
                                         v-tooltip="{ content: inHouseServiceMap[shoppingCartItem.inHouseServiceId].description[locale] }"
                                         size="24"
                                         class="text-grayBlue cursor-help"
                              ></info-icon>
                            </button>
                          </div>
                        </li>
                      </template>
                    </ul>
                  </div>
                  <div class="edit">
                  </div>
                </div>
              </template>
            </template>
          </template>

          <!--          <div class="guests px-24 border-b border-grayLight pb-24 mb-24">-->
          <!--            <div class="notes flex justify-between mb-8">-->
          <!--              <span class="font-semibold text-14 text-dark">Anmerkungen</span>-->
          <!--              <button>-->
          <!--                <span class="text-14 text-primary font-medium text-right block">Ändern</span>-->
          <!--              </button>-->
          <!--            </div>-->
          <!--            <div class="details">-->
          <!--              <div class="mb-4">-->
          <!--                <span class="text-16 text-dark">I tätat gern a Kerzn aufs Zimmer hobn. De Kerzn hob i scho gern!</span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="edit">-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="contact-information px-24">
            <div class="title flex justify-between mb-8">
              <span class="font-semibold text-14 text-dark">{{ $t('summary.contact') }}</span>
              <button>
                <span class="text-14 text-primary font-medium text-right block" @click="$router.push({ name: 'contact' })">{{ $t('summary.change') }}</span>
              </button>
            </div>
            <div v-if="!isEmpty(contactData)" class="details">
              <div class="mb-4">
                <p class="text-16 text-dark mb-16 whitespace-pre-line">
                  <template v-if="!isEmpty(contactData.firstName)">
                    {{ contactData.firstName }}
                  </template>{{ contactData.lastName }}<br />
                  <template v-if="!isEmpty(contactData.address)">
                    {{ contactData.address }}<br />
                  </template>
                  <template v-if="!isEmpty(contactData.zipCode)">
                    {{ contactData.zipCode }}
                  </template> {{ contactData.city }}
                  <template v-if="!isEmpty(contactData.zipCode) || !isEmpty(contactData.city)">
                    <br />
                  </template>
                  {{ $countries.getNames(locale)[contactData.countryCode] }}<br /><br />
                  <template v-if="!isEmpty(contactData.phoneNumber)">
                    {{ contactData.phoneNumber }}<br />
                  </template>
                  <template v-if="!isEmpty(contactData.email)">
                    {{ contactData.email }}<br />
                  </template>
                </p>
              </div>
              <div v-if="contactData.alternateBillingAddress">
                <span class="text-gray text-12 font-medium mb-8 block">{{ $t('summary.alternateBillingAddress') }}</span>
                <p class="text-16 text-dark whitespace-pre-line">
                  <template v-if="!isEmpty(contactData.companyName)">
                    {{ contactData.companyName }}<br />
                  </template>
                  <template v-if="!isEmpty(contactData.companyFirstName)">
                    {{ contactData.companyFirstName }}
                  </template>
                  <template v-if="!isEmpty(contactData.companyLastName)">
                    {{ contactData.companyLastName }}
                  </template>
                  <template v-if="!isEmpty(contactData.companyFirstName) && !isEmpty(contactData.companyLastName)">
                    <br />
                  </template>
                  <template v-if="!isEmpty(contactData.companyAddress)">
                    {{ contactData.companyAddress }}<br />
                  </template>
                  <template v-if="!isEmpty(contactData.companyZipCode)">
                    {{ contactData.companyZipCode }}
                  </template> {{ contactData.companyCity }}
                  <template v-if="!isEmpty(contactData.companyZipCode) || !isEmpty(contactData.companyCity)">
                    <br />
                  </template>
                  {{ $countries.getNames(locale)[contactData.companyCountryCode] }}
                </p>
              </div>
            </div>
            <div class="edit">
            </div>
          </div>
        </div>

        <h1 class="h1 mb-32">
          {{ $t('summary.costs') }}
        </h1>
        <cost-summary />

        <template v-if="preferredPaymentServiceProviderOptions.length > 1 && (totalPrepaymentAmount > 0 || ['GuaranteesRequired'].includes(guaranteePolicyType))">
          <hr class="border-t border-1 border-grayLight mb-24 block" />
          <input-select v-model="preferredPaymentServiceProvider"
                        name="preferredPaymentServiceProvider"
                        :values="preferredPaymentServiceProviderOptions"
                        :label="$t('summary.preferredPaymentServiceProvider')"
                        :placeholder="$t('summary.preferredPaymentServiceProviderPlaceholder')"
                        :optional="false"
          />
        </template>

        <hr class="border-t border-1 border-grayLight mb-24 block" />

        <checkbox v-if="!settings.hideBusinessTermsCheckbox"
                  v-model="businessTerms"
                  :invalid="isInvalid('businessTerms')"
                  :validation="$v.businessTerms"
                  :label="$t('summary.businessTerms')"
                  name="businessTerms"
        >
          <span @click="clickBusinessTerms" v-html="$t('summary.businessTermsCheckbox')" />
        </checkbox>
        <checkbox v-if="settings.showPrivacyCheckbox"
                  v-model="privacy"
                  :invalid="isInvalid('privacy')"
                  :validation="$v.privacy"
                  :label="$t('summary.privacy')"
                  name="privacy"
        >
          <span @click="clickPrivacy" v-html="$t('summary.privacyCheckbox')" />
        </checkbox>

        <button-big @click="book">
          {{ $t('summary.actionBook') }}
        </button-big>

        <div class="w-full text-center text-gray mt-24 text-12">
          {{ $t('summary.infoTextForwardToPaymentPage') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoIcon } from 'vue-feather-icons';
import Checkbox from '../InputElements/Checkbox/Checkbox';
import ButtonBig from '../ButtonBig/ButtonBig';
import { cloneDeep, isEmpty, isFunction, isNil } from 'lodash/lang';
import fecha from 'fecha';
import { head, join, last, uniq } from 'lodash/array';
import DateRangeMixin from '@/mixins/DateRangeMixin';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { find, keyBy } from 'lodash/collection';
import { sum } from 'lodash/math';
import { validationMixin } from 'vuelidate';
import TimerMixin from '@/mixins/TimerMixin';
import scrollIntoView from 'scroll-into-view-if-needed';
import { v4 as uuidv4 } from 'uuid';
import Loading from '@/components/lib/Loading';
import InputSelect from '../InputElements/InputSelect/InputSelect';
import CostSummary from '@/components/lib/Summary/CostSummary';
import Cookies from 'js-cookie';
import DOMPurify from 'dompurify';

export default {
  name: 'Summary',
  components: {
    CostSummary,
    Loading,
    InfoIcon,
    checkbox: Checkbox,
    'button-big': ButtonBig,
    InputSelect,
  },

  mixins: [DateRangeMixin, BookingEngineMixin, TimerMixin, validationMixin],

  props: {

  },

  validations() {
    const ret = {};

    if (!isNil(this.settings) && !this.settings.hideBusinessTermsCheckbox) {
      ret.businessTerms = {
        accepted: (value) => value === true,
      };
    }

    if (!isNil(this.settings) && this.settings.showPrivacyCheckbox) {
      ret.privacy = {
        accepted: (value) => value === true,
      };
    }

    return ret;
  },

  data: function () {
    return {
      id: uuidv4(),
      businessTerms: false,
      privacy: false,
      loading: false,
      preferredPaymentServiceProvider: null,
      mounted: false,
    };
  },

  computed: {
    offersAvailable() {
      return this.mounted ? this.roomRequests
        .filter((it) => isNil(this.getOffer(it.id)))
        .length === 0 : [];
    },
    reservationRequest() {
      let sourceName = this.$store.getters.sourceName || Cookies.get(`${this.hotelId}_source`);
      if (isEmpty(sourceName) || sourceName === false) sourceName = null;

      const ret = {
        id: this.id,
        arrivalDate: this.$store.getters.arrivalDate,
        departureDate: this.$store.getters.departureDate,
        preferredPsp: this.totalPrepaymentAmount <= 0 ? null : this.preferredPaymentServiceProvider,
        sourceName: sourceName,
        discountCode: this.discountCode,
        booker: {
          firstName: this.contactData.firstName,
          lastName: this.contactData.lastName,
          address: this.contactData.address,
          zipCode: this.contactData.zipCode,
          city: this.contactData.city,
          countryCode: this.contactData.countryCode,
          phoneNumber: this.contactData.phoneNumber,
          email: this.contactData.email,
        },
        roomRequests: this.roomRequests
          .map((it) => {
            const offer = this.getOffer(it.id);
            return {
              id: it.id,
              numAdults: it.numAdults,
              numChildren: it.childAgeCategories.length,
              childAge: it.childAgeCategories.map((acId) => ({ ageCategoryId: acId })) || [],
              roomCount: 1,
              roomTypeId: offer.roomTypeId,
              ratePlanId: offer.ratePlanId,
              maxTotalAmount: offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].amount + this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].excludedTaxAmount,
              shoppingCart: this.shoppingCartByRoomRequest[it.id].shoppingCart
                .filter((itItem) => itItem.mandatory !== true)
                .filter((itItem) => !isNil(itItem.inHouseServiceId))
                .map((itItem) => ({
                  ihsId: itItem.inHouseServiceId,
                  count: itItem.itemCount,
                })),
            };
          }),
      };

      if (this.contactData.alternateBillingAddress) {
        ret.billing = {
          companyName: this.contactData.companyName,
          firstName: this.contactData.companyFirstName,
          lastName: this.contactData.companyLastName,
          address: this.contactData.companyAddress,
          zipCode: this.contactData.companyZipCode,
          city: this.contactData.companyCity,
          countryCode: this.contactData.companyCountryCode,
        };
      }

      return ret;
    },
    contactData() {
      return this.$store.getters.contactData;
    },
    roomRequests: function () {
      return this.$store.getters.roomRequests;
    },
    selectedOffers() {
      return this.$store.getters.selectedOffers;
    },
    offerDetails() {
      return this.roomRequests
        .map((it) => this.getOffer(it.id));
    },
    guaranteePolicyType() {
      return last(this.offerDetails
        .filter((it) => !isNil(it) && !isNil(it.policies))
        .map((it) => it.policies.guaranteePolicyType)
        .sort((o1, o2) => {
          const getVal = function (guaranteePolicyType) {
            switch (guaranteePolicyType) {
              case null:
                return 0;
              case 'NoGuaranteesAccepted': return 10;
              case 'GuaranteesAccepted': return 20;
              case 'GuaranteesRequired': return 30;
              default: return 0;
            }
          };

          return getVal(o1) - getVal(o2);
        })) || 'NoGuaranteesAccepted';
    },
    canAddManual() {
      const manualPayment = head(this.settings.paymentConfiguration.paymentMethodConfiguration.filter((it) => it.paymentServiceProvider === 'Manual'));

      if (isNil(manualPayment)) return false;

      const lastDay = this.addDays(new Date(), manualPayment.minDaysBeforeArrival);
      const arrivalDate = this.$store.getters.arrivalDate;
      if (isNil(arrivalDate)) return false;
      const checkInDate = fecha.parse(arrivalDate, 'YYYYMMDD');
      return manualPayment.active === true && checkInDate >= lastDay;
    },
    availablePaymentServiceProviders() {
      const ret = [this.hotel.details.onlineBookingSettings.paymentConfiguration.primaryPaymentServiceProvider];

      if (this.canAddManual) {
        switch (this.guaranteePolicyType) {
          case 'NoGuaranteesAccepted':
          case 'GuaranteesAccepted':
            ret.push('Manual');
            break;
          case 'GuaranteesRequired':
            if (this.hotel.details.onlineBookingSettings.paymentConfiguration.allowManualPaymentAsGuarantee === true) ret.push('Manual');
            break;
          default:
            break;
        }
      }
      return uniq(ret.filter((it) => !isNil(it)));
    },
    preferredPaymentServiceProviderOptions() {
      return this.availablePaymentServiceProviders
        .map((it) => ({
          code: it,
          label: this.$t(`PaymentServiceProvider.${it}`),
        }));
    },
  },

  watch: {
    availablePaymentServiceProviders: function () {
      if (!this.availablePaymentServiceProviders.includes(this.preferredPaymentServiceProvider)) {
        this.preferredPaymentServiceProvider = isEmpty(this.availablePaymentServiceProviders) ? null : head(this.availablePaymentServiceProviders);
      }
    },
  },

  beforeMount: function () {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!

    const selectedOffers = this.$store.getters.selectedOffers;
    const roomRequests = this.$store.getters.roomRequests;
    const completedRoomRequestIds = selectedOffers
      .filter((it) => !isNil(it.roomRequestId) && !isNil(it.roomTypeId) && !isNil(it.ratePlanId))
      .map((it) => it.roomRequestId);
    const openRoomRequestIds = roomRequests
      .map((it) => it.id)
      .filter((it) => !completedRoomRequestIds.includes(it));

    if (openRoomRequestIds.length > 0 || isEmpty(roomRequests) || isEmpty(selectedOffers)) {
      this.$router.push({
        name: 'roomselect',
        params: { roomRequestId: openRoomRequestIds[0] },
      });
      return;
    }

    const contactData = this.$store.getters.contactData;
    if (isNil(contactData) || contactData.complete !== true) {
      this.$router.push({ name: 'contact' });
    }
  },

  created: function () {
    if (!this.offersAvailable && !isNil(this.roomTypeAvailability)) {
      this.$serviceBus.$emit('infoBox.msg', this.$t('summary.selectedOffersNotAvailable'));
      this.$serviceBus.$emit('quote');
      this.$router.push({ name: 'home' });
    } else {
      this.$serviceBus.$emit('quote');
      this.setTimeout(() => {
        if (!this.offersAvailable && !isNil(this.roomTypeAvailability)) {
          this.$serviceBus.$emit('infoBox.msg', this.$t('summary.selectedOffersNotAvailable'));
          this.$router.push({ name: 'home' });
        }
      }, 2000);
    }
  },

  mounted: function () {
    this.mounted = true;
    this.track();
  },

  methods: {
    dompurify(s) {
      return DOMPurify.sanitize(s);
    },
    addDays(date, days) {
      const ret = new Date(date);
      ret.setDate(ret.getDate() + days);
      return ret;
    },
    book() {
      this.validate();
      if (!this.$v.$invalid) {
        this.gtagTrack(
          'event',
          'checkout_progress',
          {
            checkout_option: `${this.preferredPaymentServiceProvider}`,
            'value': this.totalAmount,
            'currency': this.hotel.details.currency,
          },
        );
        this.loading = true;
        this.$superagent
          .post(this.serviceUrls.hotel.reservationRequest(this.hotelId))
          .query(`locale=${this.locale}`)
          .set('Content-Type', 'application/json')
          .send(this.reservationRequest)
          .then((res) => {
            if (!isEmpty(res.body.data.checkoutRedirectUrl)) {
              window.location = res.body.data.checkoutRedirectUrl;
            } else if (!isEmpty(res.body.data.confirmationNumber)) {
              switch (res.body.data.psp) {
                case 'Manual':
                default:
                  this.$router.push({
                    name: 'payment',
                    params: { confirmationNumber: res.body.data.confirmationNumber },
                  });
                  break;
              }
            } else {
              this.$serviceBus.$emit('infoBox.msg', this.$t('errors.failedToCreateReservation'));
              this.gtagTrack('event', 'exception', { description: this.$t('errors.failedToCreateReservation') });
            }
          })
          .catch((err) => {
            this.$serviceBus.$emit('infoBox.msg', this.$t('errors.failedToCreateReservationCatch'));
            this.gtagTrack('event', 'exception', { description: this.$t('errors.failedToCreateReservationCatch') });
          })
          .finally(() => {
            this.loading = false;
            this.id = uuidv4();
          });
      }
    },
    validate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.setTimeout(() => {
          const invalidElement = this.$el.querySelector('label.invalid');
          if (isFunction(invalidElement.scrollIntoView)) {
            scrollIntoView(invalidElement,
              {
                scrollMode: 'if-needed',
                block: 'nearest',
                inline: 'nearest',
                behavior: 'smooth',
              });
          }
        }, 250);
      }
    },
    isInvalid(fieldName) {
      return !isNil(this.$v[fieldName]) && this.$v[fieldName].$dirty && this.$v[fieldName].$invalid;
    },

    formatDate(date, sourceFormat) {
      const format = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };

      return new Intl.DateTimeFormat(this.locale, format).format(fecha.parse(date, sourceFormat));
    },
    clickBusinessTerms(event) {
      const target = event.target;
      if (!isNil(target) && target.tagName === 'A') {
        event.stopPropagation();
        event.preventDefault();
        this.showBusinessTerms();
      }
    },
    clickPrivacy(event) {
      const target = event.target;
      if (!isNil(target) && target.tagName === 'A') {
        event.stopPropagation();
        event.preventDefault();
        this.showPrivacy();
      }
    },
    track() {
      try {
        if (this.trackingEnabled) {
          this.$gtag.query('event', 'begin_checkout', {
            'items': this.roomRequests.map((roomRequest) => {
              const selectedOffer = this.selectedOffersMap[roomRequest.id];
              const roomType = this.roomTypeMap[selectedOffer.roomTypeId];
              const ratePlan = this.ratePlanMap[selectedOffer.ratePlanId];
              const offer = this.getOffer(selectedOffer.roomRequestId);
              return {
                'id': `${selectedOffer.roomTypeId}/${selectedOffer.ratePlanId}`,
                'name': `${roomType.name[this.hotel.details.primaryLanguage]} / ${ratePlan.name[this.hotel.details.primaryLanguage]}`,
                'list_position': this.selectedOffers.indexOf(selectedOffer),
                'category': 'Product',
                'quantity': 1,
                'price': offer.unitPriceSummary.amount + offer.unitPriceSummary.excludedTaxAmount + this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].amount + this.shoppingCartSummaryByRoomRequest[offer.roomRequestId].excludedTaxAmount,
              };
            }),
          });
        }
        // eslint-disable-next-line no-empty
      } catch (ignore) { }
    },
    displayShoppingCart(roomRequestId) {
      const shoppingCartWrapper = this.selectedShoppingCartItems.find((it) => it.roomRequestId === roomRequestId);

      if (isEmpty(shoppingCartWrapper) || isEmpty(shoppingCartWrapper.shoppingCartItems)) return false;

      return true;
    },
    getRatePlanTooltip(roomRequestId, roomType, ratePlan) {
      const offer = this.getOffer(roomRequestId);
      if (isEmpty(offer)) return '';
      const policies = offer.policies;

      let ret = '<div class="policy-tooltip">';

      if (!isNil(policies)) {
        const cancellationPolicy = ((policies.quotePolicyDetails || {}).cancellationPolicy || {})[this.locale];
        const noShowPolicy = ((policies.quotePolicyDetails || {}).noShowPolicy || {})[this.locale];
        const prepaymentPolicy = ((policies.quotePolicyDetails || {}).prepaymentPolicy || {})[this.locale];

        if (!isEmpty(cancellationPolicy) || !isEmpty(noShowPolicy) || !isEmpty(prepaymentPolicy)) {
          ret += `<p><b>${this.$t('summary.policies')}</b></p>`;
        }

        if (!isEmpty(cancellationPolicy)) ret += `<p>${cancellationPolicy}</p>`;

        if (!isEmpty(noShowPolicy)) ret += `<p>${noShowPolicy}</p>`;

        if (!isEmpty(prepaymentPolicy)) ret += `<p>${prepaymentPolicy}</p>`;

        if (!isEmpty(cancellationPolicy) || !isEmpty(noShowPolicy) || !isEmpty(prepaymentPolicy)) {
          ret += '<hr class="mt-8" />';
        }
      }

      if (!isEmpty((ratePlan.description || {})[this.locale])) {
        ret += `<p>${(ratePlan.description || {})[this.locale]}</p>`;
      }

      if (!isEmpty((roomType.description || {})[this.locale])) {
        if (!isEmpty((ratePlan.description || {})[this.locale])) {
          ret += '<hr class="mt-8" />';
        }
        ret += `<p>${(roomType.description || {})[this.locale]}</p>`;
      }

      ret += '</div>';

      return ret;
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

  .summay-rateplan-tooltip {
    p:not(:first-child) {
      @apply mt-8;
    }
  }

  .addons {
    &__list {
      li {
        @apply mb-8 flex gap-x-8;

        &:last-of-type {
          @apply mb-0;
        }
      }
    }
  }

</style>

<template>
  <div class="mb-24">
    <label class="input-label text-14 text-dark font-medium mb-8 block" :for="name">
      {{ label }} <span class="text-gray">{{ optional ? $t('Input.optional') : '' }}</span>
    </label>

    <v-select :id="name"
              v-model="selectValue"
              :name="name"
              :placeholder="placeholder"
              :options="values"
              :clearable="false"
              :select-on-tab="true"
    >
      <template slot="open-indicator">
        <chevron-down-icon size="1.5x" class="text-gray" />
      </template>
      <template slot="selected-option" slot-scope="props">
        <table>
          <tr>
            <td>
              {{ props[labelExpression] }}
            </td>
          </tr>
        </table>
      </template>
      <template slot="option" slot-scope="props">
        <table>
          <tr>
            <td>
              {{ props[labelExpression] }}
            </td>
          </tr>
        </table>
      </template>
    </v-select>
    <error-message :label="label" :invalid="invalid" :validation="validation" />
  </div>
</template>

<script>

import vSelect from 'vue-select';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { find } from 'lodash/collection';
import { isNil } from 'lodash/lang';
import { ChevronDownIcon } from 'vue-feather-icons';
import InputMixin from '@/mixins/InputMixin';
import ErrorMessage from '@/components/lib/InputElements/ErrorMessage';

export default {
  components: {
    'v-select': vSelect,
    ChevronDownIcon,
    ErrorMessage,
  },

  mixins: [BookingEngineMixin, InputMixin],
  props: {
    value: String,
    placeholder: String,
    values: Array,
    valueExpression: {
      type: String,
      default: 'code',
    },
    labelExpression: {
      type: String,
      default: 'label',
    },
  },

  data: function () {
    return {
      internalValue: null,
      selectValue: null,
    };
  },

  computed: {
  },

  watch: {
    value: function (newVal) {
      this.internalValue = newVal;
      this.selectValue = find(this.values, (it) => it[this.valueExpression] === newVal);
    },
    internalValue: function () {
      this.$emit('input', this.internalValue);
      this.$emit('input:value', this.internalValue);
    },
    selectValue: function (newVal) {
      this.internalValue = isNil(newVal) ? null : newVal.code;
    },
  },

  created: function () {
    this.internalValue = this.value;
    this.selectValue = find(this.values, (it) => it[this.valueExpression] === this.value);
  },

  mounted: function () {
    this.mounted = true;
  },

  methods: {

  },
};
</script>

<style lang="scss">
</style>
